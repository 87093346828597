* {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-bottom: 0;
}

.cardInput {
	font-size: 15px;
}

.cardInput::placeholder {
	font-size: 12px;
	color: gray;
	font-weight: bold;
}

button:disabled {
	background-color: #a697e9 !important;
	cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 5px !important;
	outline: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
	border: 1px solid #7765c4 !important;
	outline: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
	border: 1px solid #7765c4 !important;
	outline: 0 !important;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
	border-color: #7765c4 !important;
	box-shadow: none !important;
}

.ant-picker:hover,
.ant-picker-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:active,
.ant-input-affix-wrapper:hover {
	border: 1px solid #7765c4 !important;
	outline: 0 !important;
	box-shadow: none !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
	border: 1px solid #7765c4 !important;
	outline: 0 !important;
	box-shadow: none !important;
}
.ant-input-affix-wrapper > input.ant-input,
input.ant-input::selection {
	background: #fff !important;
}
.ant-spin-dot-item {
	background-color: #7765c4 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #7765c4 !important;
}

.form-wrapper {
	display: flex;
	flex-direction: column !important;
	background-color: "red" !important;
}
