@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "avenir";
	src: url("./fonts/Avenir-Medium-09.ttf");
	font-style: normal;
}

* {
	font-family: avenir;
}

.flexed {
	display: flex;
	justify-content: center;
	align-items: center;
}

@layer components {
	.pry-bg {
		@apply bg-[#fef4ff];
	}

	.pry-clr {
		@apply text-[#7765c4];
	}

	.sec-clr {
		@apply text-[#443586];
	}

	.secondary-bg {
		@apply bg-[#f9f9f9];
	}

	.mild-black {
		@apply text-[rgba(0,0,0,0.45)] !important;
	}

	.activeTab {
		@apply text-[#7765c4] font-bold border-b-2 border-[#7765c4];
	}
}

#saveCard:checked ~ .tick {
	@apply text-opacity-100;
}
